import cn from 'classnames';
import styles from './Gallery.module.scss';
import { ProjectType } from '@/types';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/effect-cards';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import { Button } from '../Button';
import { useState } from 'react';
import CircularText from '../CircularText';
import { useTranslations } from 'next-intl';
import { useMobileWidth } from '@/hooks/useMobileWidth';
import { Arrow } from '../Arrow';

type GalleryProps = {
  className?: string;
  items: ProjectType[];
};

export function SwiperButton({
  isNextButton,
  swiperRef,
}: {
  isNextButton?: boolean;
  disabled?: boolean;
  swiperRef: SwiperClass | undefined;
}) {
  const handleClick = () => {
    if (isNextButton) {
      swiperRef?.slideNext();
    } else {
      swiperRef?.slidePrev();
    }
  };

  return isNextButton ? (
    <Button
      onClick={handleClick}
      customIcon={<Arrow className={styles.arrow} />}
      className={styles.button}
    />
  ) : (
    <Button
      onClick={handleClick}
      customIcon={<Arrow className={styles.arrowLeft} />}
      className={styles.button}
    />
  );
}

export function Gallery({ className, items }: GalleryProps) {
  const wrapperClassName = cn(styles.wrapper, className);
  const [swiperRef, setSwiperRef] = useState<SwiperClass>();
  const t = useTranslations();
  const isMobile = useMobileWidth();

  const imageClassName = cn(styles.img, {
    [styles.mobile]: isMobile,
  });

  const handleClick = (link: string) => {
    window.open(link, '_blank');
  };

  return (
    <div className={wrapperClassName}>
      <Swiper
        className={styles.slider}
        effect="cards"
        modules={[Autoplay]}
        centeredSlides
        autoplay={{
          delay: 5000,
          disableOnInteraction: true,
          pauseOnMouseEnter: true,
        }}
        loop
        onSwiper={setSwiperRef}
      >
        {items.map((item, index) => (
          <SwiperSlide key={`${item.title}-${index}`}>
            <div
              key={item.link}
              onClick={() => handleClick(item.link)}
              className={styles.item}
            >
              <h4 className={styles.title}>{t(item.title)}</h4>
              <img
                src={isMobile ? item.imgMob : item.img}
                alt={item.title}
                className={imageClassName}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={styles.buttons}>
        <SwiperButton swiperRef={swiperRef} />
        <SwiperButton isNextButton swiperRef={swiperRef} />
      </div>
      <CircularText text={t('cases.openSource')} className={styles.circle} />
    </div>
  );
}

import cn from 'classnames';
import styles from './Title.module.scss';
import { motion } from 'framer-motion';

type TitleProps = {
  className?: string;
  text: string;
};

export function Title({ className, text }: TitleProps) {
  const wrapperClassName = cn(styles.wrapper, className);

  return (
    <motion.h1
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.6 }}
      variants={{
        visible: { transform: 'translateY(0%)', opacity: 1 },
        hidden: { transform: 'translateY(100%)', opacity: 0 },
      }}
      className={wrapperClassName}
    >
      {text}
    </motion.h1>
  );
}

'use client';
import cn from 'classnames';
import styles from './Navigation.module.scss';
import { useEffect, useRef, useState } from 'react';
import { useTranslations } from 'next-intl';
import { useMobileWidth } from '@/hooks/useMobileWidth';

type NavigationProps = {
  className?: string;
};

type TabVariant = 'home' | 'experience' | 'contacts' | 'about' | 'cases';

type TabType = {
  id: TabVariant;
  title: string;
};

type SvgIconProps = {
  className?: string;
};

const AboutSvg = ({ className }: SvgIconProps) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="2048.000000pt"
      height="2048.000000pt"
      viewBox="0 0 2048.000000 2048.000000"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g
        transform="translate(0.000000,2048.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M16245 20304 c-547 -42 -970 -154 -1470 -390 -817 -385 -1549 -1108
-1963 -1939 -280 -560 -414 -1112 -414 -1700 -1 -380 47 -689 159 -1032 l37
-112 -106 -113 c-58 -62 -251 -271 -429 -464 -1235 -1335 -1320 -1427 -1326
-1420 -14 13 -183 1093 -288 1841 -47 336 -110 781 -140 991 l-54 381 40 99
c50 122 91 266 115 404 26 144 26 482 0 620 -89 478 -335 874 -716 1152 -411
299 -964 420 -1475 323 -394 -75 -730 -253 -985 -522 -151 -159 -267 -287
-313 -343 -174 -214 -303 -490 -364 -779 -25 -115 -27 -145 -27 -351 0 -209 2
-234 28 -353 119 -555 453 -1000 959 -1282 38 -21 73 -47 77 -59 5 -11 19 -93
30 -181 37 -278 169 -1201 206 -1435 37 -237 81 -512 214 -1315 45 -269 106
-643 136 -830 78 -491 143 -895 149 -935 5 -33 0 -41 -102 -150 -105 -113
-510 -550 -903 -975 -107 -116 -305 -330 -440 -475 -135 -145 -265 -286 -290
-313 l-46 -50 -189 53 c-172 47 -1136 315 -1640 455 -110 30 -258 72 -330 91
-395 109 -772 215 -787 222 -9 4 -44 61 -77 128 -194 389 -520 678 -931 824
-210 75 -459 108 -688 90 -532 -42 -701 -105 -1079 -405 -440 -349 -703 -789
-777 -1303 -70 -481 26 -959 272 -1355 197 -319 565 -631 890 -753 178 -68
438 -99 630 -75 114 14 410 89 540 138 172 63 135 64 427 -16 143 -40 454
-126 690 -193 237 -66 468 -131 515 -144 47 -12 167 -46 268 -73 100 -28 262
-73 360 -100 255 -71 707 -197 847 -236 66 -19 140 -39 164 -45 l44 -11 -6
-57 c-15 -138 -377 -2622 -392 -2690 -3 -13 -38 -57 -78 -99 -148 -154 -257
-341 -353 -608 -99 -278 -128 -434 -127 -705 0 -183 3 -219 26 -331 71 -338
225 -624 466 -867 140 -141 366 -300 535 -378 l54 -24 793 2 794 3 90 48 c802
429 1155 1299 874 2148 -41 125 -153 352 -229 468 -59 89 -62 96 -57 140 3 25
29 199 56 386 226 1534 452 3142 458 3250 l6 110 52 55 c28 30 299 323 602
650 302 327 675 730 828 895 152 165 391 423 529 573 l251 273 140 -6 c847
-36 1542 -87 2645 -196 1760 -172 2481 -262 3165 -395 99 -19 187 -38 197 -42
9 -4 43 -47 76 -97 209 -311 540 -561 893 -673 207 -66 280 -77 539 -76 218 0
244 2 358 28 354 78 665 248 931 509 425 416 649 995 603 1559 -20 248 -68
426 -172 644 -192 402 -535 717 -959 879 -191 73 -354 104 -576 112 -261 9
-491 -31 -726 -128 l-98 -40 -183 40 c-924 202 -1637 298 -3708 500 -656 64
-632 62 -628 71 4 11 1332 1448 1350 1461 5 3 37 0 71 -8 232 -49 333 -57 672
-58 346 0 417 6 675 56 1074 208 2057 873 2688 1819 375 564 606 1207 663
1852 17 191 6 605 -19 773 -137 889 -584 1612 -1284 2078 -212 141 -467 267
-541 267 -13 0 -66 22 -118 49 -210 110 -620 188 -1024 195 -110 2 -222 2
-250 0z m-7386 -2004 c205 -42 368 -128 512 -270 108 -108 157 -178 206 -294
53 -124 66 -193 66 -341 -1 -153 -20 -238 -82 -367 -228 -472 -842 -657 -1346
-405 -108 54 -157 88 -244 171 -198 191 -293 425 -278 689 20 353 248 647 607
781 151 56 386 72 559 36z m10086 -7875 c111 -23 221 -84 315 -172 160 -151
246 -322 270 -542 45 -412 -179 -790 -545 -918 -75 -26 -90 -28 -235 -27 -142
0 -161 3 -230 27 -268 97 -466 334 -526 626 -25 127 -16 336 21 451 89 282
295 490 555 560 62 17 95 19 190 15 63 -2 147 -11 185 -20z m-16857 -1005
c363 -103 611 -443 589 -809 -7 -124 -26 -194 -83 -310 -83 -168 -252 -314
-434 -375 -216 -72 -455 -48 -660 67 -89 50 -238 194 -289 280 -77 131 -111
256 -111 412 0 133 20 221 74 330 113 228 315 381 558 425 83 14 272 4 356
-20z m4753 -6754 c203 -43 412 -184 529 -356 106 -156 148 -319 129 -503 -18
-177 -97 -329 -232 -446 -294 -254 -756 -217 -1062 84 -95 94 -145 174 -185
298 -44 134 -53 196 -47 306 16 295 218 534 522 616 66 18 263 18 346 1z"
        />
      </g>
    </svg>
  );
};

const HomeSvg = ({ className }: SvgIconProps) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="2048.000000pt"
      height="2048.000000pt"
      viewBox="0 0 2048.000000 2048.000000"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g
        transform="translate(0.000000,2048.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M9895 20364 c-386 -58 -704 -219 -966 -490 -41 -44 -95 -105 -119
 -137 -47 -63 -30 -49 -880 -693 -1103 -835 -2495 -1890 -2895 -2194 -236 -179
 -551 -418 -700 -530 -148 -112 -445 -337 -660 -500 -214 -163 -642 -487 -950
 -720 -308 -234 -708 -537 -890 -675 l-330 -250 -43 -75 c-23 -41 -49 -95 -58
 -120 -9 -25 -31 -74 -49 -110 -68 -132 -172 -425 -230 -645 l-26 -100 6 -5520
 6 -5520 35 -370 35 -370 71 -140 c119 -235 232 -386 388 -522 283 -245 614
 -377 995 -397 61 -3 2774 -8 6030 -11 3256 -3 6838 -8 7960 -11 1769 -5 2056
 -3 2160 9 164 21 265 45 265 63 0 8 41 58 90 110 109 114 207 244 303 402 100
 165 263 502 282 584 16 72 36 798 35 1313 0 143 4 355 10 470 5 116 14 577 19
 1025 10 801 15 1520 46 6025 8 1238 19 2408 25 2600 15 580 -9 746 -175 1185
 -36 96 -85 216 -110 265 -103 211 -262 403 -457 549 -61 45 -575 379 -1103
 716 -66 42 -507 325 -980 630 -473 304 -1402 902 -2065 1328 -663 427 -1653
 1063 -2200 1415 -547 352 -1197 770 -1445 930 -457 293 -559 351 -721 406
 -170 58 -269 73 -484 76 -107 2 -208 1 -225 -1z m4750 -8894 c704 -11 1305
 -24 1335 -29 201 -34 340 -79 525 -173 489 -247 798 -658 892 -1186 16 -88 17
 -181 14 -1047 -8 -2248 -27 -5444 -33 -5450 -10 -11 -762 -15 -3530 -19
 l-2618 -5 5 512 c2 282 27 1648 55 3037 38 1907 48 2558 41 2661 -35 493 133
 941 480 1274 257 247 524 377 899 438 59 10 740 5 1935 -13z"
        />
      </g>
    </svg>
  );
};

const ExpSvg = ({ className }: SvgIconProps) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="2048.000000pt"
      height="2048.000000pt"
      viewBox="0 0 2048.000000 2048.000000"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g
        transform="translate(0.000000,2048.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M6225 19589 c-140 -21 -234 -63 -700 -314 -1384 -743 -1829 -1012
 -2186 -1322 -207 -179 -345 -378 -399 -579 -34 -124 -39 -346 -11 -494 58
 -303 190 -580 538 -1120 54 -85 146 -231 203 -323 132 -215 131 -211 60 -402
 -76 -204 -109 -264 -162 -296 -159 -97 -400 -145 -673 -136 -200 7 -309 20
 -704 87 -363 62 -516 80 -696 80 -377 0 -618 -104 -764 -329 -81 -126 -129
 -302 -161 -599 -247 -2230 -265 -2423 -257 -2677 8 -252 54 -385 171 -500 110
 -108 228 -153 457 -176 131 -12 231 -10 704 17 352 21 730 23 810 5 82 -18
 130 -44 159 -88 28 -41 82 -203 152 -458 107 -394 319 -974 564 -1550 28 -66
 93 -221 143 -345 79 -193 92 -233 92 -281 -1 -89 -49 -173 -165 -290 -132
 -134 -207 -182 -437 -284 -414 -182 -544 -242 -733 -337 -588 -294 -962 -563
 -1056 -761 -92 -195 -62 -324 159 -687 341 -557 727 -1122 1186 -1730 374
 -497 549 -682 762 -811 154 -92 348 -133 525 -111 285 36 509 137 1169 527
 594 351 728 413 905 422 118 6 205 -13 422 -93 456 -167 644 -228 953 -309
 434 -113 1015 -223 1665 -314 612 -86 978 -117 1595 -136 275 -9 298 -13 419
 -81 106 -59 293 -186 386 -263 144 -119 156 -134 387 -508 185 -301 299 -473
 429 -643 425 -560 827 -815 1199 -760 114 16 213 44 350 97 152 59 689 330
 1280 645 253 135 633 336 845 448 633 334 847 459 1042 604 299 222 461 413
 529 625 70 218 25 420 -141 627 -118 147 -381 369 -1010 849 -179 137 -356
 274 -395 305 l-70 56 -3 102 -3 102 53 47 c322 284 615 684 945 1293 179 329
 296 576 463 980 179 431 257 554 392 616 63 29 63 29 258 29 122 -1 264 -8
 380 -20 277 -30 767 -28 970 4 376 58 589 153 763 340 208 225 282 536 302
 1256 29 1085 26 1716 -10 1960 -56 377 -214 672 -457 855 -181 137 -385 209
 -698 249 -302 38 -959 74 -1377 75 l-322 1 -6 35 c-13 72 -78 279 -121 385
 -121 296 -333 629 -694 1090 -376 482 -663 789 -1040 1115 -113 97 -212 189
 -220 204 -13 26 -12 32 18 89 36 71 96 151 265 357 249 305 351 450 433 615
 144 293 134 539 -30 769 -144 201 -547 497 -1189 872 -749 438 -1271 663
 -1742 751 -130 24 -383 29 -490 9 -142 -27 -259 -88 -360 -190 -134 -135 -493
 -611 -775 -1029 -28 -42 -34 -43 -122 -22 -216 50 -640 69 -982 46 -656 -46
 -1293 -149 -1991 -321 -132 -33 -265 -62 -296 -66 -55 -6 -57 -5 -98 32 -57
 51 -127 173 -283 492 -215 438 -358 693 -511 912 -360 514 -659 725 -962 679z
 m103 -376 c-10 -2 -26 -2 -35 0 -10 3 -2 5 17 5 19 0 27 -2 18 -5z m-71 -9
 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m4123 -5439 c236 -27
 412 -71 635 -158 1083 -421 1920 -1521 2092 -2747 25 -176 25 -627 0 -791
 -126 -844 -609 -1473 -1318 -1717 -335 -116 -690 -137 -1075 -66 -1061 195
 -2035 1115 -2414 2279 -110 339 -160 653 -160 1004 0 526 129 981 389 1367
 328 490 812 774 1421 837 92 10 318 6 430 -8z"
        />
      </g>
    </svg>
  );
};

const CasesSvg = ({ className }: SvgIconProps) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="2048.000000pt"
      height="2048.000000pt"
      viewBox="0 0 2048.000000 2048.000000"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g
        transform="translate(0.000000,2048.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M10190 19424 c-2282 -93 -4234 -725 -5490 -1778 -118 -99 -384 -360
 -495 -486 -409 -466 -691 -1010 -809 -1560 -54 -252 -61 -325 -60 -650 0 -240
 3 -327 17 -409 20 -125 21 -140 5 -146 -845 -262 -1254 -426 -1875 -752 -321
 -169 -410 -229 -558 -377 -152 -152 -230 -268 -339 -503 -161 -346 -195 -502
 -196 -898 0 -145 13 -317 65 -880 36 -385 106 -1136 155 -1670 49 -533 112
 -1213 139 -1510 28 -297 64 -686 80 -865 17 -179 53 -570 81 -870 87 -943 157
 -1698 235 -2540 41 -448 82 -853 91 -900 87 -455 380 -847 791 -1058 235 -120
 422 -161 808 -177 156 -6 445 -20 755 -35 113 -6 588 -28 1055 -50 468 -22
 1185 -56 1595 -75 828 -39 1200 -56 2450 -115 470 -22 1091 -51 1380 -65 289
 -13 768 -36 1065 -50 297 -14 729 -34 960 -45 627 -30 972 -46 1800 -85 1243
 -58 2397 -113 3195 -151 856 -41 972 -41 1154 1 294 66 521 192 736 405 174
 172 288 341 368 545 79 199 104 354 117 725 7 228 109 3053 140 3890 14 377
 43 1180 65 1785 22 605 44 1222 50 1370 52 1367 81 2243 77 2360 -14 372 -62
 582 -195 844 -259 513 -507 781 -993 1074 -121 73 -426 229 -581 298 -47 21
 -78 40 -78 49 0 8 11 61 25 117 90 362 108 806 50 1183 -164 1054 -866 2004
 -2005 2717 -218 136 -324 195 -560 313 -1145 574 -2491 907 -4075 1010 -158
 11 -1031 21 -1195 14z m1210 -3038 c614 -53 1097 -134 1606 -272 783 -210
 1444 -533 1805 -881 89 -86 176 -189 167 -199 -3 -2 -105 16 -227 41 -869 176
 -1770 283 -2896 341 -413 21 -1500 30 -1945 15 -1118 -36 -2159 -133 -3239
 -301 -140 -22 -256 -38 -258 -37 -7 7 127 155 190 211 635 561 1899 976 3297
 1081 306 23 298 23 825 20 341 -2 546 -8 675 -19z m5365 -5131 c109 -29 183
 -74 276 -165 67 -66 94 -102 122 -160 67 -137 71 -166 64 -510 -14 -660 -50
 -979 -143 -1265 -136 -417 -391 -711 -774 -894 -382 -183 -865 -255 -2050
 -306 -195 -8 -397 -18 -449 -21 l-93 -6 5 -35 c3 -19 12 -144 18 -277 26 -502
 -26 -816 -187 -1141 -83 -168 -171 -291 -314 -439 -357 -369 -839 -607 -1440
 -711 -205 -35 -334 -45 -580 -45 -387 0 -726 54 -1090 172 -558 181 -1133 531
 -1463 889 -163 176 -299 408 -366 621 -61 194 -76 305 -76 581 0 240 -1 248
 -20 252 -11 2 -96 6 -190 10 -1508 51 -2917 416 -3560 920 -285 225 -480 522
 -559 856 -74 309 -86 837 -23 1017 66 189 212 334 402 399 71 25 94 28 205 27
 111 0 133 -3 200 -28 159 -59 289 -176 361 -321 57 -116 68 -189 67 -450 -2
 -406 28 -464 305 -606 447 -228 1095 -395 1882 -484 566 -63 930 -80 1785 -79
 837 0 931 3 3550 104 2226 87 2582 109 2930 187 229 50 276 81 314 202 48 150
 69 379 85 926 8 246 11 288 31 350 70 221 237 378 465 437 84 21 218 18 310
 -7z"
        />
      </g>
    </svg>
  );
};

const ContactsSvg = ({ className }: SvgIconProps) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="2048.000000pt"
      height="2048.000000pt"
      viewBox="0 0 2048.000000 2048.000000"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g
        transform="translate(0.000000,2048.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M19285 19253 c-173 -27 -479 -81 -645 -113 -906 -172 -1614 -289
 -3435 -565 -165 -25 -356 -54 -425 -65 -69 -11 -228 -35 -355 -54 -126 -20
 -349 -54 -495 -76 -146 -22 -339 -52 -430 -66 -91 -14 -338 -52 -550 -85 -212
 -33 -484 -75 -605 -94 -121 -19 -337 -53 -480 -75 -143 -23 -372 -59 -510 -80
 -301 -48 -661 -105 -1002 -160 -139 -22 -364 -58 -500 -80 -375 -59 -550 -88
 -748 -120 -192 -31 -583 -94 -990 -159 -137 -22 -364 -58 -502 -81 -139 -22
 -367 -58 -505 -80 -1035 -166 -1128 -181 -2053 -334 -923 -154 -1482 -249
 -2030 -346 -93 -16 -258 -45 -365 -64 -201 -36 -622 -111 -955 -172 -104 -19
 -275 -49 -380 -68 -565 -103 -636 -117 -703 -145 -148 -60 -281 -183 -351
 -325 -53 -108 -71 -186 -71 -324 0 -105 -2 -116 -29 -165 -53 -95 -75 -188
 -75 -317 -1 -212 25 -268 286 -631 327 -453 857 -1189 948 -1314 48 -66 228
 -316 400 -555 172 -239 382 -529 465 -645 301 -418 652 -904 804 -1115 85
 -118 211 -294 281 -390 70 -96 134 -187 143 -201 19 -28 11 -143 -78 -1269
 -79 -989 -187 -1886 -386 -3195 -34 -226 27 -434 174 -591 84 -90 192 -159
 301 -193 71 -22 101 -25 246 -26 159 -1 169 -2 275 -35 102 -33 119 -35 235
 -34 119 1 134 4 300 52 208 60 566 160 890 248 287 79 1098 282 1425 358 132
 30 328 76 435 101 329 77 429 99 434 94 4 -4 376 -762 566 -1154 112 -230
 1006 -2057 1134 -2315 102 -207 148 -274 243 -352 116 -96 292 -158 451 -158
 46 0 71 -5 90 -19 105 -74 240 -114 382 -114 117 0 198 20 310 75 67 33 101
 58 170 127 75 76 97 108 186 266 92 162 430 758 699 1230 102 178 459 806 902
 1585 127 223 306 538 398 700 92 162 277 486 410 720 244 430 588 1033 853
 1500 80 140 254 446 387 680 133 234 329 578 435 765 106 187 293 516 415 730
 255 447 886 1557 1013 1780 47 83 194 341 327 575 133 234 383 673 555 975
 461 812 536 942 845 1485 155 272 351 617 435 765 193 340 536 944 825 1450
 122 215 262 460 310 545 48 85 124 218 168 295 44 77 93 167 108 200 70 147
 78 346 20 504 -54 147 -155 275 -282 355 -31 19 -73 58 -94 85 -84 115 -213
 205 -360 251 -69 21 -225 34 -290 23z m-2815 -1878 c0 -3 -37 -23 -82 -44
 -141 -65 -768 -366 -1810 -867 -145 -70 -324 -156 -398 -192 -74 -35 -205 -98
 -290 -139 -170 -82 -504 -243 -870 -418 -129 -62 -392 -188 -585 -280 -414
 -198 -780 -373 -1665 -795 -360 -172 -704 -336 -765 -365 -266 -127 -816 -390
 -1110 -530 -441 -210 -925 -441 -1340 -640 -192 -92 -451 -216 -575 -275 -694
 -332 -941 -450 -1180 -565 -146 -70 -371 -178 -500 -240 -520 -249 -684 -328
 -894 -428 -121 -58 -224 -104 -228 -101 -10 7 -70 87 -283 384 -93 129 -286
 397 -429 595 -143 198 -322 448 -399 555 -77 107 -156 218 -177 245 -20 28
 -106 147 -192 265 -85 118 -266 370 -403 560 -137 190 -323 449 -414 575 -224
 309 -215 295 -205 305 5 5 176 38 379 75 204 36 442 79 530 95 224 41 867 154
 1360 239 770 134 1721 292 2690 447 217 35 433 69 995 159 157 26 382 62 500
 80 302 49 599 96 925 149 349 57 661 107 875 141 91 15 260 42 375 60 116 19
 284 46 375 60 91 15 275 44 410 65 693 111 1030 164 1525 240 138 22 396 62
 575 90 179 28 384 59 455 70 72 10 202 31 290 44 88 14 324 51 525 81 201 30
 437 66 525 80 196 31 564 87 785 120 91 14 275 43 410 64 256 40 290 45 290
 36z m957 -1612 c-39 -68 -317 -558 -382 -673 -48 -85 -179 -315 -290 -510
 -111 -195 -251 -440 -310 -545 -59 -104 -217 -381 -350 -615 -133 -234 -282
 -495 -330 -580 -48 -85 -188 -330 -310 -545 -122 -214 -280 -491 -350 -615
 -161 -283 -741 -1303 -930 -1635 -78 -137 -245 -430 -370 -650 -125 -220 -339
 -596 -475 -835 -136 -239 -341 -599 -455 -800 -237 -417 -560 -984 -910 -1600
 -133 -234 -300 -526 -370 -650 -70 -124 -246 -432 -390 -685 -144 -253 -392
 -688 -550 -968 -159 -279 -293 -506 -298 -504 -11 3 -1407 2852 -1407 2871 0
 6 98 122 217 256 264 298 1127 1270 2193 2470 437 492 1026 1156 1309 1475
 773 870 1667 1877 2736 3080 528 595 1196 1347 1485 1673 447 504 562 629 537
 585z m-3957 -1366 c-8 -8 -112 -95 -230 -193 -220 -184 -551 -461 -925 -774
 -115 -97 -363 -304 -550 -460 -187 -156 -407 -341 -490 -410 -82 -69 -260
 -218 -395 -331 -581 -486 -871 -729 -1115 -933 -143 -120 -417 -349 -610 -510
 -192 -161 -465 -389 -605 -506 -306 -257 -687 -575 -1100 -921 -168 -140 -428
 -358 -579 -485 -151 -126 -430 -359 -620 -518 -328 -274 -669 -560 -1291
 -1081 -407 -340 -441 -368 -444 -364 -2 2 12 123 30 269 75 588 129 1150 204
 2130 56 732 68 910 64 927 -2 10 -1 23 4 30 4 7 167 88 362 181 195 94 474
 227 620 297 561 270 773 371 990 475 124 59 383 183 575 275 1032 494 1284
 614 1925 920 239 114 590 282 780 372 638 305 1013 484 1310 625 162 77 405
 193 540 258 135 65 441 211 680 325 239 115 532 254 650 311 212 102 248 117
 220 91z m-2765 -4119 c-15 -18 -469 -530 -1009 -1138 -540 -608 -1152 -1297
 -1360 -1532 l-379 -427 -156 -36 c-86 -20 -210 -50 -276 -65 -66 -16 -219 -52
 -340 -80 -121 -28 -296 -69 -389 -91 -93 -22 -170 -38 -173 -36 -2 3 144 128
 324 279 180 151 451 378 602 504 151 126 500 418 776 649 276 231 704 589 950
 795 1103 923 1447 1210 1452 1210 3 0 -7 -15 -22 -32z"
        />
      </g>
    </svg>
  );
};

const tabs: TabType[] = [
  {
    id: 'home',
    title: 'home.title',
  },
  {
    id: 'experience',
    title: 'experience.title',
  },
  {
    id: 'cases',
    title: 'cases.title',
  },
  {
    id: 'about',
    title: 'about.title',
  },
  {
    id: 'contacts',
    title: 'contacts.title',
  },
];

export function Navigation({ className }: NavigationProps) {
  const [visible, setVisible] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [activeTab, setActiveTab] = useState<TabVariant | null>(null);
  const isMobile = useMobileWidth();
  const t = useTranslations();
  const scrollTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const wrapperClassName = cn(
    styles.wrapper,
    {
      [styles.visible]: visible,
    },
    className,
  );

  const handleTabClick = (id: TabVariant) => {
    setClicked(true);
    if (scrollTimeoutRef.current) {
      clearTimeout(scrollTimeoutRef.current);
    }

    if (id === 'home') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      const element = document.getElementById(id)?.getBoundingClientRect();
      if (element) {
        const position = window.scrollY + element.top;
        window.scrollTo({
          top: position - (isMobile ? 30 : 140),
          behavior: 'smooth',
        });
      }
    }
    setActiveTab(id);

    scrollTimeoutRef.current = setTimeout(() => {
      setClicked(false);
    }, 3000);
  };

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      const isBottom =
        window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight;

      if (isBottom) {
        setVisible(false);
        return;
      }

      if (position > 50) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    };

    document.addEventListener('scroll', handleScroll);

    return () => document.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      if (clicked) return;

      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveTab(entry.target.id as TabVariant);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, {
      root: null,
      rootMargin: '-50% 0px -50% 0px',
      threshold: 0,
    });

    const ids: TabVariant[] = [
      'home',
      'experience',
      'cases',
      'about',
      'contacts',
    ];

    ids.forEach((id) => {
      const element = document.getElementById(id);
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      ids.forEach((id) => {
        const element = document.getElementById(id);
        if (element) {
          observer.unobserve(element);
        }
      });

      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
    };
  }, [clicked]);

  return (
    <div className={wrapperClassName}>
      {tabs.map(({ id, title }) => {
        const isActive = id === activeTab;
        const tabClassName = cn(styles.tab, {
          [styles.active]: isActive,
        });
        const iconClassName = cn(styles.icon, {
          [styles.iconActive]: isActive,
        });

        return (
          <div
            key={id}
            className={tabClassName}
            onClick={() => handleTabClick(id)}
          >
            {id === 'home' && <HomeSvg className={iconClassName} />}
            {id === 'experience' && <ExpSvg className={iconClassName} />}
            {id === 'cases' && <CasesSvg className={iconClassName} />}
            {id === 'about' && <AboutSvg className={iconClassName} />}
            {id === 'contacts' && <ContactsSvg className={iconClassName} />}
            {isActive && <span className={styles.title}>{t(title)}</span>}
          </div>
        );
      })}
    </div>
  );
}

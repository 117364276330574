import cn from 'classnames';
import styles from './Tag.module.scss';

export enum TagVariant {
  Primary = 'primary',
  Secondary = 'secondary',
}

type TagProps = {
  className?: string;
  text: string;
  variant?: TagVariant;
};

export function Tag({
  className,
  text,
  variant = TagVariant.Primary,
}: TagProps) {
  const wrapperClassName = cn(
    styles.wrapper,
    {
      [styles.secondary]: variant === TagVariant.Secondary,
    },
    className,
  );

  return <div className={wrapperClassName}>{text}</div>;
}

import cn from 'classnames';
import styles from './Marquee.module.scss';
import MarqueeComponent from 'react-fast-marquee';

type MarqueeProps = {
  items: string[];
  icon?: string;
  className?: string;
};

export function Marquee({ items, icon, className }: MarqueeProps) {
  const wrapperClassName = cn(styles.wrapper, className);

  return (
    <MarqueeComponent className={wrapperClassName} autoFill pauseOnHover>
      {items.map((el, index) => (
        <div key={el + index}>
          <span className={styles.text}>{el}</span>
          {icon && <img className={styles.img} src={icon} alt="marquee" />}
        </div>
      ))}
    </MarqueeComponent>
  );
}

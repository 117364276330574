import cn from 'classnames';
import styles from './CompanyCard.module.scss';
import { ReactNode, useState } from 'react';
import { useTabletWidth } from '@/hooks/useTabletWidth';
import { motion } from 'framer-motion';

type CompanyCardProps = {
  className?: string;
  title: string;
  link: string;
  tag?: ReactNode;
  desc: ReactNode;
  unhovered?: boolean;
  animationDuration: number;
};

export function CompanyCard({
  className,
  title,
  link,
  tag,
  desc,
  unhovered,
  animationDuration,
}: CompanyCardProps) {
  const [hovered, setHovered] = useState(false);
  const [clicked, setClicked] = useState(false);
  const isTablet = useTabletWidth();

  const wrapperClassName = cn(styles.wrapper, className);

  const cardClassName = cn(
    styles.card,
    {
      [styles.hovered]: hovered && !unhovered,
      [styles.clicked]: clicked,
    },
    className,
  );

  const descClassName = cn(styles.desc, {
    [styles.active]: hovered || isTablet,
  });

  const handleMouseDown = () => {
    setClicked(true);
  };

  const handleMouseUp = () => {
    setClicked(false);
  };

  const handleMouseOver = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleClick = () => {
    if (unhovered) return;

    window.open(link, '_blank');
  };

  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: animationDuration }}
      variants={{
        visible: { transform: 'translateY(0%)', opacity: 1 },
        hidden: { transform: 'translateY(50%)', opacity: 0 },
      }}
      onMouseOver={handleMouseOver}
      className={wrapperClassName}
    >
      <div
        className={cardClassName}
        onClick={handleClick}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onTouchStart={handleMouseDown}
        onTouchEnd={handleMouseUp}
      >
        <div className={styles.heading}>
          <span className={styles.title}>{title}</span>
          {tag && tag}
        </div>
        <div className={descClassName}>{desc}</div>
      </div>
    </motion.div>
  );
}

'use client';
import { Experience } from '@/layout/Experience';
import styles from './page.module.scss';
import { Main } from '@/layout/Main';
import { Marquee } from '@/components/Marquee';
import { Projects } from '@/layout/Projects';
import { AboutMe } from '@/layout/AboutMe';
import { Switchers } from '@/layout/Switchers';
import { Contacts } from '@/layout/Contacts';
import { Docs } from '@/layout/Docs';
import { Navigation } from '@/layout/Navigation';

export default function Home() {
  return (
    <div className={styles.main}>
      <Switchers />
      <Main />
      <Marquee
        items={[
          'HTML',
          'CSS',
          'React',
          'Redux',
          'Next',
          'JavaScript',
          'NodeJS',
          'MobX',
          'SCSS',
          'Tailwind',
          'TypeScript',
          'Git',
          'Webpack',
        ]}
        className={styles.marquee}
      />
      <Experience />
      <Projects />
      <AboutMe />
      <Contacts />
      <Docs />
      <Navigation />
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import styles from './CircularText.module.scss';
import cn from 'classnames';

type CircularTextProps = {
  text: string;
  spacing?: number;
  className?: string;
};

const CircularText = ({ text, spacing = 1, className }: CircularTextProps) => {
  const [characters, setCharacters] = useState<string[]>([]);
  const wrapperClassName = cn(styles.wrapper, className);

  useEffect(() => {
    setCharacters(text.split(''));
  }, [text]);

  const characterCount = characters.length;
  const innerAngle = 360 / characterCount;
  const radius = (spacing / Math.sin(innerAngle * (Math.PI / 180))) * -1;

  return (
    <div className={wrapperClassName}>
      {characters.map((char, index) => {
        const rotateAngle = -innerAngle * index;

        return (
          <span
            key={index}
            className={styles.char}
            style={{
              transform: `
                translate(-50%, -50%)
                rotate(${rotateAngle}deg)
                translateY(${radius}ch)
                rotate(180deg)
              `,
            }}
            aria-hidden="true"
          >
            {char}
          </span>
        );
      })}
      <span className={styles.text}>{text}</span>
    </div>
  );
};

export default CircularText;

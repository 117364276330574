import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './SlideText.module.scss';

type SlideTextProps = {
  className?: string;
  items: string[];
  timer: number;
  onChange?: (item: string) => void;
};

export function SlideText({
  className,
  items,
  timer,
  onChange,
}: SlideTextProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderClassName = cn(styles.slider, className);

  useEffect(() => {
    if (onChange) onChange(items[currentIndex]);
  }, [currentIndex, onChange, items]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
    }, timer);

    return () => clearInterval(interval);
  }, [items.length, timer]);

  return (
    <div className={sliderClassName}>
      {items.map((line, index) => (
        <div
          key={index}
          className={cn(styles.line, {
            [styles.active]: index === currentIndex,
            [styles.inactive]: index !== currentIndex,
          })}
        >
          {line}
        </div>
      ))}
    </div>
  );
}

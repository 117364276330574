import cn from 'classnames';
import styles from './Contacts.module.scss';
import { Title } from '@/components/Title';
import { WorkingHours } from '@/components/WorkingHours';
import { useTranslations } from 'next-intl';
import { Chat } from '@/components/Chat';
import toast, { Toaster } from 'react-hot-toast';
import { Button } from '@/components/Button';
import { FramerBlock } from '@/components/FramerBlock';
import { Arrow } from '@/components/Arrow';

type ContactsProps = {
  className?: string;
};

export function Contacts({ className }: ContactsProps) {
  const wrapperClassName = cn(styles.wrapper, className);
  const t = useTranslations();

  const handleTelegramClick = () => {
    window.open('https://t.me/boymep', '_blank');
  };

  const handleWAClick = () => {
    window.open('https://wa.me/79039109854', '_blank');
  };

  const handleEmailClick = () => {
    navigator.clipboard.writeText('dan1lpanov@mail.ru');
    toast.success(t('contacts.emailCopied'));
    window.open('mailto: dan1lpanov@mail.ru');
  };

  return (
    <div className={wrapperClassName} id="contacts">
      <Title text={t('contacts.title')} className={styles.title} />
      <div className={styles.content}>
        <Chat />
        <div className={styles.contacts}>
          <WorkingHours />
          <FramerBlock
            transition={1.5}
            variants={{
              visible: { transform: 'translateY(0%)', opacity: 1 },
              hidden: { transform: 'translateY(50%)', opacity: 0 },
            }}
            className={styles.buttons}
          >
            <Button
              text="telegram"
              onClick={handleTelegramClick}
              customIcon={<Arrow className={styles.arrow} />}
              animatedIcon
            />
            <Button
              text="whatsapp"
              onClick={handleWAClick}
              customIcon={<Arrow className={styles.arrow} />}
              animatedIcon
            />
            <Button text="email" onClick={handleEmailClick} />
          </FramerBlock>
        </div>
        <Toaster
          toastOptions={{
            success: {
              duration: 5000,
            },
          }}
        />
      </div>
    </div>
  );
}

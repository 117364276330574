type MoonProps = { className?: string };

export function Moon({ className }: MoonProps) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="2048.000000pt"
      height="2048.000000pt"
      viewBox="0 0 2048.000000 2048.000000"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g
        transform="translate(0.000000,2048.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path d="M9703 20323 c32 -2 81 -2 110 0 29 2 3 3 -58 3 -60 0 -84 -1 -52 -3z" />
        <path d="M10238 20323 c28 -2 76 -2 105 0 28 2 5 3 -53 3 -58 0 -81 -1 -52 -3z" />
        <path d="M9463 20313 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z" />
        <path d="M10553 20313 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z" />
        <path d="M11138 20263 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path d="M8808 20253 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path d="M9953 19703 c37 -2 97 -2 135 0 37 2 6 3 -68 3 -74 0 -105 -1 -67 -3z" />
        <path d="M9688 19693 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
        <path d="M10328 19693 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
        <path
          d="M9680 19674 c-281 -15 -465 -28 -613 -44 -184 -20 -629 -85 -688
-100 -19 -5 -114 -26 -210 -45 -213 -43 -734 -178 -794 -205 -11 -5 -117 -42
-235 -81 -118 -39 -227 -78 -242 -85 -15 -8 -60 -26 -100 -40 -40 -14 -100
-38 -133 -53 -33 -15 -82 -36 -110 -46 -27 -10 -59 -24 -70 -31 -11 -7 -42
-21 -70 -32 -128 -50 -619 -301 -761 -388 -39 -24 -73 -44 -76 -44 -21 0 -559
-353 -691 -453 -16 -12 -39 -28 -52 -37 -78 -52 -418 -325 -584 -470 -349
-304 -749 -721 -1026 -1070 -44 -56 -147 -189 -169 -219 -11 -14 -50 -66 -86
-116 -78 -105 -217 -305 -255 -367 -15 -23 -44 -70 -66 -103 -48 -74 -218
-359 -286 -480 -88 -156 -224 -429 -343 -690 -208 -455 -448 -1168 -550 -1635
-16 -74 -33 -148 -39 -165 -5 -16 -16 -70 -25 -120 -9 -49 -25 -139 -37 -200
-32 -169 -46 -262 -74 -494 -14 -118 -27 -217 -30 -220 -2 -4 -12 -108 -22
-231 -26 -322 -26 -1196 0 -1520 10 -123 20 -227 22 -230 2 -3 20 -136 39
-295 20 -160 41 -303 45 -320 5 -16 15 -61 21 -100 31 -185 52 -295 65 -345 8
-30 28 -118 45 -195 29 -136 90 -368 144 -550 40 -134 126 -407 137 -430 5
-11 26 -69 48 -130 63 -175 116 -312 161 -420 23 -55 53 -127 67 -160 25 -60
46 -106 149 -326 69 -147 206 -417 249 -489 17 -30 62 -109 99 -175 92 -163
133 -234 160 -275 13 -19 50 -78 82 -130 69 -110 273 -414 357 -530 48 -67
121 -164 127 -170 3 -3 34 -43 70 -90 162 -215 522 -633 709 -823 91 -92 130
-141 85 -108 -10 8 3 -8 31 -37 27 -29 51 -52 54 -52 3 0 -13 18 -34 40 -21
22 -35 40 -29 40 5 0 41 -32 79 -71 39 -39 138 -132 220 -206 167 -152 187
-172 180 -183 -3 -4 3 -14 12 -21 12 -10 14 -10 9 -1 -11 19 8 14 38 -9 15
-12 25 -27 22 -34 -3 -8 3 -19 12 -27 11 -9 14 -10 8 -1 -15 26 16 12 57 -25
48 -44 315 -259 388 -312 436 -321 815 -569 1130 -743 261 -144 513 -273 694
-355 167 -76 286 -128 325 -142 19 -8 62 -26 95 -40 33 -15 85 -35 115 -45 30
-10 71 -26 90 -35 47 -23 513 -180 670 -226 454 -131 934 -234 1375 -294 494
-67 1141 -101 1545 -82 229 12 681 53 895 82 400 54 974 177 1285 275 275 87
466 149 475 155 6 4 39 17 75 30 402 143 895 372 1270 589 174 100 218 126
267 155 82 50 422 276 492 327 36 27 90 65 119 86 29 21 72 54 96 73 24 19 67
53 96 75 29 22 72 56 95 75 23 19 73 60 110 90 37 30 80 66 96 80 15 14 53 48
85 75 32 28 72 63 89 79 72 70 234 216 240 216 3 0 3 -6 -1 -12 -4 -7 5 -2 19
11 27 25 35 37 17 26 -5 -3 -10 -2 -10 2 0 5 41 49 91 98 138 136 594 657 661
755 10 14 33 43 50 66 98 123 320 431 401 554 128 198 407 721 512 960 15 36
38 88 50 115 90 200 188 454 223 577 90 314 173 756 212 1123 15 140 29 495
21 536 -6 33 -21 43 -38 25 -5 -5 -3 -6 5 -2 27 16 7 -13 -103 -149 -119 -149
-349 -395 -368 -395 -6 0 2 15 19 32 21 24 18 23 -13 -6 -24 -22 -40 -42 -38
-45 7 -6 -86 -101 -99 -101 -5 0 -1 10 10 23 10 12 -5 0 -34 -27 -28 -28 -49
-52 -46 -55 6 -6 -107 -121 -120 -121 -5 0 1 12 15 28 13 15 5 9 -18 -12 -24
-22 -40 -42 -38 -45 6 -6 -56 -71 -68 -71 -4 0 -5 6 -1 13 4 6 -5 1 -19 -12
-15 -14 -25 -27 -22 -30 7 -6 -46 -61 -58 -61 -5 0 -3 8 4 18 7 9 1 6 -14 -7
-15 -14 -25 -27 -22 -30 7 -6 -46 -61 -58 -61 -5 0 -3 8 4 18 13 15 12 16 -3
3 -10 -7 -15 -17 -13 -22 3 -4 -31 -38 -75 -75 -44 -37 -80 -71 -80 -77 0 -5
-6 -7 -14 -4 -8 3 -26 -9 -42 -26 -17 -19 -20 -24 -6 -13 12 11 22 15 22 10 0
-15 -223 -206 -435 -375 -236 -186 -634 -450 -923 -611 -32 -17 -72 -40 -91
-50 -18 -11 -40 -17 -47 -14 -8 3 -13 1 -11 -5 5 -22 -393 -214 -713 -344
-722 -294 -1454 -467 -2310 -547 -187 -17 -1025 -17 -1230 0 -845 72 -1615
262 -2367 584 -304 129 -713 343 -943 493 -365 237 -548 371 -815 599 -196
168 -566 541 -723 729 -806 971 -1265 2053 -1389 3277 -25 246 -24 842 0 1090
67 662 234 1296 500 1895 63 142 178 376 234 475 11 19 35 62 53 95 57 103
190 315 265 425 40 58 83 121 96 141 143 211 426 546 674 798 323 329 611 576
965 831 137 98 559 370 575 370 3 0 26 13 52 29 143 88 536 284 778 388 629
271 1380 475 2065 562 549 70 1238 80 1785 25 785 -79 1576 -289 2287 -607
212 -95 513 -249 642 -328 26 -17 50 -28 52 -26 2 2 -100 106 -228 233 -271
269 -288 285 -358 344 -30 25 -72 62 -94 82 -103 93 -370 312 -454 373 -31 22
-51 43 -48 51 3 8 0 11 -8 8 -6 -2 -30 9 -52 26 -100 77 -452 324 -533 375 -9
5 -52 33 -96 61 -91 59 -289 177 -415 249 -47 26 -119 67 -160 90 -103 59
-340 179 -490 249 -230 108 -246 115 -301 136 -30 12 -63 27 -74 34 -11 7 -60
27 -110 45 -49 19 -94 37 -100 41 -5 4 -41 18 -80 30 -38 13 -88 31 -110 41
-132 61 -659 223 -969 299 -88 22 -166 42 -175 45 -41 16 -432 90 -666 126
-286 44 -533 72 -785 89 -163 11 -824 21 -950 14z m8920 -12307 c0 -2 -15 -16
-32 -33 l-33 -29 29 33 c28 30 36 37 36 29z m-190 -181 c0 -2 -8 -10 -17 -17
-16 -13 -17 -12 -4 4 13 16 21 21 21 13z m-110 -100 c0 -2 -8 -10 -17 -17 -16
-13 -17 -12 -4 4 13 16 21 21 21 13z m-370 -336 c0 -5 -5 -10 -11 -10 -5 0 -7
5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m1077 -122 c-3 -8 -6 -5 -6 6 -1 11 2
17 5 13 3 -3 4 -12 1 -19z"
        />
        <path d="M3894 17218 l-19 -23 23 19 c21 18 27 26 19 26 -2 0 -12 -10 -23 -22z" />
        <path d="M3824 17148 l-29 -33 33 29 c17 17 32 31 32 33 0 8 -8 1 -36 -29z" />
        <path d="M7984 15618 l-19 -23 23 19 c21 18 27 26 19 26 -2 0 -12 -10 -23 -22z" />
        <path d="M7634 15268 l-29 -33 33 29 c17 17 32 31 32 33 0 8 -8 1 -36 -29z" />
        <path
          d="M7519 15143 c-13 -16 -12 -17 4 -4 16 13 21 21 13 21 -2 0 -10 -8
-17 -17z"
        />
        <path d="M7454 15068 l-19 -23 23 19 c12 11 22 21 22 23 0 8 -8 2 -26 -19z" />
        <path d="M1251 11714 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M611 11644 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M1242 11620 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
        <path d="M601 11524 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M1232 11510 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
        <path d="M1222 11390 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
        <path d="M1212 11240 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
        <path d="M1202 11045 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />
        <path d="M1202 10265 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />
        <path d="M1212 10060 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
        <path d="M1222 9920 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
        <path d="M1232 9790 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
        <path d="M602 9780 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
        <path d="M1242 9685 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />
        <path d="M611 9664 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M1251 9584 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M621 9554 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M19851 9504 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M19861 9424 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M19872 9330 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
        <path d="M19882 9215 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />
        <path d="M19893 9075 c0 -22 2 -30 4 -17 2 12 2 30 0 40 -3 9 -5 -1 -4 -23z" />
        <path d="M19903 8835 c0 -44 2 -61 4 -37 2 23 2 59 0 80 -2 20 -4 1 -4 -43z" />
        <path d="M19903 8390 c0 -47 2 -66 4 -42 2 23 2 61 0 85 -2 23 -4 4 -4 -43z" />
        <path d="M19893 8135 c0 -22 2 -30 4 -17 2 12 2 30 0 40 -3 9 -5 -1 -4 -23z" />
        <path
          d="M19233 8093 c-3 -10 -8 -22 -11 -28 -3 -5 4 -2 16 9 l22 19 3 -179 3
-179 -1 184 c0 138 -3 185 -12 189 -7 2 -16 -5 -20 -15z"
        />
        <path
          d="M19364 7830 c0 -113 2 -159 3 -102 2 56 2 148 0 205 -1 56 -3 10 -3
-103z"
        />
        <path d="M19159 7983 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z" />
        <path d="M19882 7975 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />
        <path d="M19872 7850 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
        <path d="M18989 7773 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z" />
        <path d="M19862 7740 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
        <path d="M18870 7643 l-55 -58 58 55 c31 30 57 56 57 57 0 8 -10 -1 -60 -54z" />
        <path d="M19253 7635 c0 -33 2 -45 4 -27 2 18 2 45 0 60 -2 15 -4 0 -4 -33z" />
        <path d="M19851 7644 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M19841 7554 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M19243 7510 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z" />
        <path d="M19831 7474 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M19232 7410 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z" />
        <path d="M19821 7394 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M19222 7320 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z" />
        <path d="M19212 7245 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />
        <path d="M19202 7175 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />
        <path
          d="M7350 7156 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"
        />
        <path d="M19192 7110 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
        <path
          d="M7420 7076 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"
        />
        <path d="M19182 7050 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
        <path d="M19172 6990 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
        <path
          d="M7530 6957 c0 -2 15 -16 33 -33 l32 -29 -29 33 c-28 30 -36 37 -36
29z"
        />
        <path d="M19162 6940 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
        <path d="M19152 6880 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
        <path d="M17994 6838 l-19 -23 23 19 c21 18 27 26 19 26 -2 0 -12 -10 -23 -22z" />
        <path d="M19142 6830 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
        <path d="M19131 6784 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M19121 6734 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path
          d="M7780 6707 c0 -2 15 -16 33 -33 l32 -29 -29 33 c-28 30 -36 37 -36
29z"
        />
        <path d="M19112 6690 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
        <path d="M17794 6648 l-19 -23 23 19 c21 18 27 26 19 26 -2 0 -12 -10 -23 -22z" />
        <path d="M19101 6644 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M19091 6604 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M17719 6583 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z" />
        <path
          d="M7920 6576 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"
        />
        <path d="M19076 6549 c-8 -42 0 -50 9 -9 4 17 5 34 2 36 -2 3 -7 -10 -11 -27z" />
        <path
          d="M19052 6448 c-7 -28 -11 -53 -8 -55 2 -2 9 19 16 47 7 28 10 53 8 55
-3 3 -10 -18 -16 -47z"
        />
        <path
          d="M8130 6396 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"
        />
        <path
          d="M19017 6324 c-9 -32 -35 -106 -56 -164 -22 -57 -38 -106 -37 -108 9
-9 119 304 113 322 -2 5 -11 -17 -20 -50z"
        />
        <path
          d="M8280 6276 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"
        />
        <path
          d="M18876 5947 c-112 -273 -307 -677 -464 -956 -113 -202 -200 -336
-450 -691 l-24 -35 26 30 c53 61 197 264 286 405 182 284 368 638 550 1045 56
126 121 287 117 291 -2 2 -21 -38 -41 -89z"
        />
        <path
          d="M3620 4136 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"
        />
        <path
          d="M4245 3470 c10 -11 20 -20 23 -20 3 0 -3 9 -13 20 -10 11 -20 20 -23
20 -3 0 3 -9 13 -20z"
        />
        <path d="M17174 3368 l-19 -23 23 19 c21 18 27 26 19 26 -2 0 -12 -10 -23 -22z" />
        <path
          d="M4530 3206 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"
        />
        <path d="M17364 2668 l-19 -23 23 19 c21 18 27 26 19 26 -2 0 -12 -10 -23 -22z" />
        <path d="M10153 743 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z" />
        <path d="M11603 743 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z" />
        <path d="M10278 733 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z" />
        <path d="M11458 733 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z" />
        <path d="M10458 723 c18 -2 45 -2 60 0 15 2 0 4 -33 4 -33 0 -45 -2 -27 -4z" />
        <path d="M11268 723 c18 -2 45 -2 60 0 15 2 0 4 -33 4 -33 0 -45 -2 -27 -4z" />
        <path
          d="M10788 713 c56 -2 148 -2 205 0 56 1 10 3 -103 3 -113 0 -159 -2
-102 -3z"
        />
        <path d="M10018 123 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path d="M10143 113 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z" />
        <path d="M11608 113 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
        <path d="M10293 103 c15 -2 37 -2 50 0 12 2 0 4 -28 4 -27 0 -38 -2 -22 -4z" />
        <path d="M11438 103 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z" />
        <path d="M10503 93 c26 -2 67 -2 90 0 23 2 2 3 -48 3 -49 0 -68 -1 -42 -3z" />
        <path d="M11193 93 c26 -2 67 -2 90 0 23 2 2 3 -48 3 -49 0 -68 -1 -42 -3z" />
      </g>
    </svg>
  );
}

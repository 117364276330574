import cn from 'classnames';
import styles from './Main.module.scss';
import { JumpingText } from '@/components/JumpingText';
import { useTranslations } from 'next-intl';
import { Title } from '@/components/Title';
import { Ghost } from '@/components/Ghost';

type MainProps = {
  className?: string;
};

export function Main({ className }: MainProps) {
  const wrapperClassName = cn(styles.wrapper, className);
  const t = useTranslations();

  return (
    <div className={wrapperClassName} id="home">
      <div className={styles.content}>
        <div className={styles.names}>
          <JumpingText text={t('name')} className={styles.name} />
          <JumpingText text={t('surname')} className={styles.surname} />
        </div>
        <div className={styles.desc}>
          <Title text="FRONTEND" className={styles.descText} />
          <Title text="DEVELOPER" className={styles.descText} />
        </div>
        <div className={styles.ghost}>
          <Ghost />
        </div>
      </div>
    </div>
  );
}

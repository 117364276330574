type PhoneProps = {
  className?: string;
};

export function Phone({ className }: PhoneProps) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="2048.000000pt"
      height="2048.000000pt"
      viewBox="0 0 2048.000000 2048.000000"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g
        transform="translate(0.000000,2048.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M7440 19503 c-239 -19 -547 -73 -809 -143 -304 -82 -796 -261 -1216
-445 -1177 -514 -2027 -1121 -2527 -1806 -498 -682 -689 -1508 -572 -2473 207
-1719 1397 -4125 3692 -7466 725 -1054 1610 -2269 2073 -2844 36 -44 103 -130
149 -191 207 -273 616 -732 881 -990 558 -544 1195 -991 1834 -1287 167 -77
272 -119 588 -234 202 -74 269 -94 310 -94 28 0 120 -14 204 -30 513 -101
1052 -117 1603 -49 1035 129 2101 583 3075 1311 365 272 637 509 990 863 346
345 580 611 876 991 219 282 484 659 509 724 7 19 23 46 36 60 40 44 66 117
66 184 -1 65 -41 187 -175 526 -139 356 -518 1180 -740 1613 -88 171 -685
1168 -794 1324 -281 406 -759 894 -987 1010 -110 56 -213 41 -310 -46 -88 -78
-593 -468 -756 -583 -648 -458 -1387 -793 -1985 -899 -170 -30 -487 -38 -645
-15 -478 69 -914 315 -1485 836 -571 521 -1032 1117 -1273 1643 -280 615 -316
1212 -109 1822 270 795 876 1467 1696 1880 113 57 151 88 175 147 24 55 40
162 41 259 0 83 -2 90 -43 169 -115 219 -425 707 -665 1045 -727 1025 -1617
2031 -2402 2716 -66 57 -127 111 -135 119 -9 8 -76 67 -150 130 -73 63 -160
138 -193 166 -49 43 -67 52 -110 58 -53 6 -635 5 -717 -1z"
        />
      </g>
    </svg>
  );
}

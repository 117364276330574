'use client';
import cn from 'classnames';
import styles from './WorkingHours.module.scss';
import { useLocale, useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import Marquee from 'react-fast-marquee';
import { useMobileWidth } from '@/hooks/useMobileWidth';
import { FramerBlock } from '../FramerBlock';

type WorkingHoursProps = {
  className?: string;
};

const getStatus = () => {
  const now = new Date();

  const currentDay = now
    .toLocaleDateString('ru-RU', { weekday: 'long' })
    .toLowerCase();
  const currentTime = now.toLocaleTimeString('ru-RU', {
    hour: '2-digit',
    minute: '2-digit',
  });

  const workingDays = ['понедельник', 'вторник', 'среда', 'четверг', 'пятница'];

  const startWorkingTime = '10:00';
  const endWorkingTime = '20:00';

  if (
    workingDays.includes(currentDay) &&
    currentTime >= startWorkingTime &&
    currentTime <= endWorkingTime
  ) {
    return 'Online';
  } else {
    return 'Offline';
  }
};

export function WorkingHours({ className }: WorkingHoursProps) {
  let timeNow = new Date().toLocaleTimeString('ru-RU');
  const [time, setTime] = useState(timeNow);
  const wrapperClassName = cn(styles.wrapper, className);
  const locale = useLocale();
  const t = useTranslations();
  const isMobile = useMobileWidth();

  const updateTime = () => {
    timeNow = new Date().toLocaleTimeString('ru-RU');
    setTime(timeNow);
  };

  useEffect(() => {
    const interval = setInterval(updateTime);

    return () => clearInterval(interval);
  });

  return (
    <div className={wrapperClassName}>
      <FramerBlock
        transition={0.6}
        variants={{
          visible: { transform: 'translateY(0%)', opacity: 1 },
          hidden: { transform: 'translateY(50%)', opacity: 0 },
        }}
        className={styles.current}
      >
        <span className={styles.today}>{t('contacts.now')}</span>
        <span className={styles.currentDay}>
          {new Date().toLocaleString(locale, { weekday: 'long' })}
        </span>
        <span className={styles.currentTime} suppressHydrationWarning>
          {time}
        </span>
      </FramerBlock>
      <FramerBlock
        transition={0.9}
        variants={{
          visible: { transform: 'translateY(0%)', opacity: 1 },
          hidden: { transform: 'translateY(50%)', opacity: 0 },
        }}
        className={styles.available}
      >
        <div className={styles.time}>
          <span className={styles.timeText}>{t('contacts.available')}</span>
          <div className={styles.days}>
            <span>{isMobile ? t('contacts.mon') : t('contacts.monday')}</span>
            <span>-</span>
            <span>{isMobile ? t('contacts.fri') : t('contacts.friday')}</span>
          </div>
          <span className={styles.timeNumbers}>10:00 - 20:00 МСК</span>
        </div>
      </FramerBlock>
      <FramerBlock
        transition={1.2}
        variants={{
          visible: { transform: 'translateY(0%)', opacity: 1 },
          hidden: { transform: 'translateY(50%)', opacity: 0 },
        }}
      >
        <Marquee className={styles.marquee}>
          <span className={styles.now}>
            {getStatus()} {getStatus()} {getStatus()} {getStatus()}{' '}
            {getStatus()}
          </span>
        </Marquee>
      </FramerBlock>
    </div>
  );
}

import cn from 'classnames';
import styles from './Switchers.module.scss';
import { Button, ButtonTheme } from '@/components/Button';
import { useRouter } from 'next/navigation';
import { useLocale, useTranslations } from 'next-intl';
import { useTheme } from '@/hooks/useTheme';
import { useEffect, useState } from 'react';
import { useMobileWidth } from '@/hooks/useMobileWidth';
import { Moon } from '@/components/Moon';
import { Phone } from '@/components/Phone';

type SwitchersProps = {
  className?: string;
};

export function Switchers({ className }: SwitchersProps) {
  const [hiddenTabs, setHiddenTabs] = useState(false);
  const router = useRouter();
  const locale = useLocale();
  const { toggleTheme } = useTheme();
  const t = useTranslations();
  const isMobile = useMobileWidth();

  const changeLocale = () => {
    if (locale === 'en') router.replace('/ru');
    if (locale === 'ru') router.replace('/en');
  };

  const scrollToContacts = () => {
    const element = document
      .getElementById('contacts')
      ?.getBoundingClientRect();
    if (element) {
      const position = window.scrollY + element.top;
      window.scrollTo({
        top: position - (isMobile ? 30 : 140),
        behavior: 'smooth',
      });
    }
  };

  const wrapperClassName = cn(
    styles.wrapper,
    {
      [styles.wrapperHidden]: hiddenTabs,
    },
    className,
  );
  const buttonsClassName = cn(styles.buttons, {
    [styles.hidden]: hiddenTabs,
  });

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;

      if (position > 100) {
        setHiddenTabs(true);
      }
      if (position < 100) {
        setHiddenTabs(false);
      }
    };
    document.addEventListener('scroll', handleScroll);

    return () => document.removeEventListener('scroll', handleScroll);
  }, []);

  const handleCVClick = () => {
    window.open('/pdf/DanilPanov.pdf', '_blank');
  };

  return (
    <div className={wrapperClassName}>
      <div className={buttonsClassName}>
        <Button
          text={t('contactMe')}
          onClick={scrollToContacts}
          className={styles.contact}
        />
        <Button
          customIcon={<Phone className={styles.phone} />}
          onClick={scrollToContacts}
          className={styles.contactMob}
        />
        <Button
          text="CV"
          onClick={handleCVClick}
          theme={ButtonTheme.Dark}
          className={styles.cv}
        />
      </div>
      <div className={styles.buttons}>
        <Button
          text={locale.toUpperCase()}
          onClick={changeLocale}
          className={styles.button}
        />
        <Button
          customIcon={<Moon className={styles.moon} />}
          onClick={toggleTheme}
          className={styles.button}
          theme={ButtonTheme.Dark}
        />
      </div>
    </div>
  );
}

import { motion } from 'framer-motion';
import { ReactNode } from 'react';

type FramerBlockProps = {
  transition: number;
  variants: {
    visible: {
      transform: string;
      opacity: number;
    };
    hidden: {
      transform: string;
      opacity: number;
    };
  };
  className?: string;
  children: ReactNode;
};

export function FramerBlock({
  variants,
  transition,
  children,
  className,
}: FramerBlockProps) {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: transition }}
      variants={variants}
      className={className}
    >
      {children}
    </motion.div>
  );
}

import cn from 'classnames';
import styles from './Chat.module.scss';
import Typewriter from 'typewriter-effect';
import { useTranslations } from 'next-intl';
import { FramerBlock } from '../FramerBlock';

type ChatProps = {
  className?: string;
};

type ChatSvgProps = {
  className?: string;
};

const ChatSvg = ({ className }: ChatSvgProps) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="2070.000000pt"
      height="1220.000000pt"
      viewBox="0 0 2070.000000 1220.000000"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g
        transform="translate(0.000000,1220.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M14215 12049 c-3371 -8 -5675 -21 -6830 -39 -330 -5 -1007 -14 -1505
 -20 -498 -6 -1364 -17 -1925 -25 -561 -8 -1454 -19 -1985 -25 -531 -6 -985
 -13 -1009 -16 -25 -3 -94 -31 -160 -65 -292 -149 -499 -397 -616 -738 -40
 -116 -57 -216 -82 -491 -16 -171 -18 -559 -20 -5230 -2 -2775 -1 -5055 2
 -5067 7 -31 54 -61 126 -79 55 -14 78 -15 164 -5 144 17 223 37 261 66 35 27
 121 138 754 980 211 281 486 645 610 810 124 165 358 476 520 690 162 215 416
 552 565 750 149 198 397 527 550 730 153 204 287 380 298 393 l20 22 7848 0
 c7119 0 7853 1 7896 16 67 22 199 89 258 132 120 87 262 245 329 365 l34 62 6
 385 c3 212 11 1601 18 3087 l11 2701 -28 79 c-39 111 -96 203 -179 290 -87 92
 -165 149 -282 205 l-89 43 -1080 1 c-594 0 -2610 -3 -4480 -7z m-12185 -549
 c134 -49 150 -219 27 -283 -36 -19 -45 -19 -175 -8 -378 34 -661 -78 -776
 -307 -54 -108 -68 -181 -70 -367 -1 -152 -2 -162 -25 -194 -64 -89 -198 -79
 -255 19 -29 49 -35 273 -12 408 70 402 336 657 771 737 99 18 460 15 515 -5z
 m17739 -5398 c65 -47 91 -132 98 -314 6 -154 -10 -239 -67 -354 -71 -146 -170
 -246 -320 -323 -160 -82 -273 -106 -505 -106 -199 1 -345 21 -401 56 -47 29
 -78 97 -69 151 9 50 64 112 110 124 17 4 76 0 137 -10 120 -19 314 -22 398 -6
 192 37 329 130 385 263 26 64 37 165 24 242 -22 139 -22 197 1 233 41 67 148
 89 209 44z"
        />
      </g>
    </svg>
  );
};

export function Chat({ className }: ChatProps) {
  const wrapperClassName = cn(styles.wrapper, className);
  const t = useTranslations();

  return (
    <div className={wrapperClassName}>
      <FramerBlock
        transition={0.6}
        variants={{
          visible: { transform: 'translateY(0%)', opacity: 1 },
          hidden: { transform: 'translateY(50%)', opacity: 0 },
        }}
        className={styles.chat}
      >
        <ChatSvg className={styles.message} />
        <ChatSvg className={cn(styles.message, styles.messageMe)} />
        <ChatSvg className={styles.message} />
      </FramerBlock>
      <FramerBlock
        transition={1.2}
        variants={{
          visible: { transform: 'translateY(0%)', opacity: 1 },
          hidden: { transform: 'translateY(50%)', opacity: 0 },
        }}
        className={styles.input}
      >
        <Typewriter
          options={{
            strings: [
              t('contacts.chat.make'),
              t('contacts.chat.product'),
              t('contacts.chat.hunt'),
              t('contacts.chat.lead'),
            ],
            autoStart: true,
            loop: true,
          }}
        />
      </FramerBlock>
    </div>
  );
}

import cn from 'classnames';
import styles from './Docs.module.scss';
import { Button } from '@/components/Button';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/navigation';
import { Arrow } from '@/components/Arrow';

type DocsProps = {
  className?: string;
};

export function Docs({ className }: DocsProps) {
  const wrapperClassName = cn(styles.wrapper, className);
  const t = useTranslations();
  const router = useRouter();

  const handlePrivacyClick = () => {
    router.push('privacy-policy');
  };

  const handleMaterialsClick = () => {
    router.push('materials-policy');
  };

  return (
    <div className={wrapperClassName} id="policy">
      <div className={styles.buttons}>
        <Button
          text={t('policy.privacy.button')}
          onClick={handlePrivacyClick}
          className={styles.button}
          customIcon={<Arrow className={styles.arrow} />}
          animatedIcon
        />
        <p className={styles.rights}>{t('policy.rights')}</p>
        <Button
          text={t('policy.materials.button')}
          onClick={handleMaterialsClick}
          className={styles.button}
          customIcon={<Arrow className={styles.arrow} />}
          animatedIcon
        />
      </div>
    </div>
  );
}
